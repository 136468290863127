<template>
  <!-- 取消预约弹窗 -->
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="cancel-popup-header">
      <div class="cancel-popup-title">
        取消服务原因
      </div>
      <div class="close-btn" @click="onClose">关闭</div>
    </div>
    <div class="cancel-popup-picker">
      <van-picker
        title="标题"
        :columns="columns"
        value-key="label"
        @change="onChange"
        item-height="10.2vw"
        visible-item-count="4"
      />
    </div>
    <div class="cancel-popup-content" v-show="reason === 0" >
      <textarea 
        class="cancel-textarea" 
        maxlength="50" 
        v-model="reasonContent"
        placeholder="请填写取消服务原因…">
      </textarea>
      <div class="text-length">{{ reasonContent.length }}/50</div>
    </div>
    <div class="cancel-popup-ft">
      <van-button class="popup-btn" type="info" @click="onSubmit" :loading="loading">确定</van-button>
    </div>
  </van-popup>
</template>

<script>
  import { Popup, Picker, Button, Dialog } from 'vant'
  import { cancelOrder } from '@/api/order'

  export default {
    components: {
      [Popup.name]: Popup,
      [Picker.name]: Picker,
      [Button.name]: Button
    },
    props: {
      visible: {
        type: Boolean,
        value: false
      },
      id: {
      }
    },
    data() {
      return {
        reason: 1,
        reasonContent: '',
        loading: false,
        columns: [{
            id: 1,
            label: '临时有事', 
          }, 
          {
            id: 2,
            label: '机器已正常'
          }, 
          {
            id: 0,
            label: '其他（请填写取消原因）'
          }
        ]
      }
    },
    methods: {
      onChange(e, item) {
        this.reason = item.id
      },
      onClose() {
        this.$emit('close', true)
      },
      onSubmit() {
        if (this.loading == true) {
          return
        }
        Dialog.confirm({
          title: '取消预约',
          message: '确定取消预约吗？',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          this.loading = true
          const params = {
            id: this.id, 
            calcel_remark: this.reason === 0 ? this.reasonContent : this.columns[this.reason - 1].label
          }
          cancelOrder(params).then(() => {
            this.loading = false
            this.$emit('cancel', true)
          }).catch(() => {
             this.loading = false
          })
        })
        .catch(() => {
          // on cancel
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  .cancel-popup-header {
    position: relative;
    height: 17.8667vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel-popup-title {
      font-weight: bold;
      font-size: 4.5333vw;
      color: #1C1F20;
    }
    .close-btn {
      padding: 2.6667vw;
      position: absolute;
      right: 4vw;
      top: 4vw;
      font-size: 3.7333vw;
      color: #9C9DAA;
      line-height: 1.1;
      z-index: 2;
    }
  }
  .cancel-popup-picker {
    padding: 0 4vw;
  } 
  .cancel-popup-content {
    height: 34.1333vw;
    padding: 4vw 4vw 5.3333vw;
    position: relative;
    .cancel-textarea { 
      width: 100%;
      height: 24.8vw;
      background: #F4F4F4;
      border-radius: 1.0667vw;
      padding: 4vw;
      border: 0 none;
      font-size: 3.2vw;
      line-height: 1.4;
      resize: none;
    }
    .text-length {
      font-size: 2.9333vw;
      line-height: 1.1;
      color: #D5D5D5;
      letter-spacing: 0.1333vw;
      position: absolute;
      right: 8vw;
      bottom: 9.3333vw;
      z-index: 4;
    }
  }
  .cancel-popup-ft {
    padding: 4vw 4vw calc(4vw + constant(safe-area-inset-bottom));
    padding: 4vw 4vw calc(4vw + env(safe-area-inset-bottom));
    .popup-btn {
      width: 100%;
      height: 12vw;
      font-size: 4vw
    }
  }
</style>